exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-administrative-building-js": () => import("./../../../src/pages/AdministrativeBuilding.js" /* webpackChunkName: "component---src-pages-administrative-building-js" */),
  "component---src-pages-angus-js": () => import("./../../../src/pages/Angus.js" /* webpackChunkName: "component---src-pages-angus-js" */),
  "component---src-pages-breeding-horse-js": () => import("./../../../src/pages/BreedingHorse.js" /* webpackChunkName: "component---src-pages-breeding-horse-js" */),
  "component---src-pages-cinematography-js": () => import("./../../../src/pages/Cinematography.js" /* webpackChunkName: "component---src-pages-cinematography-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/Construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipment-js": () => import("./../../../src/pages/Equipment.js" /* webpackChunkName: "component---src-pages-equipment-js" */),
  "component---src-pages-fabrica-js": () => import("./../../../src/pages/Fabrica.js" /* webpackChunkName: "component---src-pages-fabrica-js" */),
  "component---src-pages-farmingfish-js": () => import("./../../../src/pages/Farmingfish.js" /* webpackChunkName: "component---src-pages-farmingfish-js" */),
  "component---src-pages-fertilizer-js": () => import("./../../../src/pages/Fertilizer.js" /* webpackChunkName: "component---src-pages-fertilizer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orchard-js": () => import("./../../../src/pages/Orchard.js" /* webpackChunkName: "component---src-pages-orchard-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personal-protective-js": () => import("./../../../src/pages/Personal-protective.js" /* webpackChunkName: "component---src-pages-personal-protective-js" */),
  "component---src-pages-rentalvisits-js": () => import("./../../../src/pages/Rentalvisits.js" /* webpackChunkName: "component---src-pages-rentalvisits-js" */),
  "component---src-pages-romanov-sheep-js": () => import("./../../../src/pages/RomanovSheep.js" /* webpackChunkName: "component---src-pages-romanov-sheep-js" */),
  "component---src-pages-schoolequipment-js": () => import("./../../../src/pages/Schoolequipment.js" /* webpackChunkName: "component---src-pages-schoolequipment-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/Sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/Tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vip-cars-js": () => import("./../../../src/pages/VIPCars.js" /* webpackChunkName: "component---src-pages-vip-cars-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

